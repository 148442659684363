<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      :before-close="handleClose"
      width="60%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="beteen">
          <div>
            <el-form-item label="市场名称" prop="comp_name">
              <el-input
                v-model="ruleForm.comp_name"
                placeholder="请输入市场名称"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="市场编码" prop="comp_id">
              <el-input
                v-model="ruleForm.comp_id"
                placeholder="请输入市场编码"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="社会信用代码" prop="reg_id">
              <el-input
                v-model="ruleForm.reg_id"
                placeholder="请输入统一社会信用代码"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人代表" prop="legal_represent">
              <el-input
                v-model="ruleForm.legal_represent"
                placeholder="请输入法人代表"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="tel">
              <el-input
                v-model="ruleForm.tel"
                placeholder="请输入联系电话"
                style="width:217px"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="传真" prop="fax">
              <el-input
                v-model="ruleForm.fax"
                placeholder="请输入传真"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="备案日期" prop="record_date">
              <el-date-picker
                v-model="ruleForm.record_date"
                type="date"
                placeholder="请选择备案日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备案过期时间">
              <el-date-picker
                v-model="ruleForm.record_expiration_date"
                type="date"
                placeholder="请选择备案过期时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="营业时间" prop="business_start">
              <el-time-picker
                class="picker"
                is-range
                v-model="timePicker"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="请选择营业时间"
                value-format="HH:mm:ss"
              >
              </el-time-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="省份" prop="province_id">
              <el-select
                v-model="ruleForm.province_id"
                placeholder="请选择省份"
              >
                <el-option
                  v-for="item in infomation.CityArea"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="CityID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市" prop="city_id">
              <el-select v-model="ruleForm.city_id" placeholder="请选择城市">
                <el-option
                  v-for="item in infomation.City"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="areaID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区县" prop="area_id">
              <el-select v-model="ruleForm.area_id" placeholder="请选择区县">
                <el-option
                  v-for="item in infomation.Area"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="streetID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="街道" prop="street_id">
              <el-select
                v-model="ruleForm.street_id"
                placeholder="请选择街道(乡镇)"
              >
                <el-option
                  v-for="item in infomation.Street"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址" prop="addr">
              <el-input
                v-model="ruleForm.addr"
                placeholder="请输入详细地址"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="覆盖人群约" prop="covered_population">
              <el-input
                v-model="ruleForm.covered_population"
                placeholder="请输入覆盖人群约"
                style="width:217px"
              >
                <i slot="suffix" style="font-style:normal">人</i>
              </el-input>
            </el-form-item>
            <el-form-item label="市场面积" prop="market_footprint">
              <el-input
                v-model="ruleForm.market_footprint"
                placeholder="请输入市场面积"
                style="width:217px"
              >
                <i slot="suffix" style="font-style:normal">㎡</i>
              </el-input>
            </el-form-item>
            <el-form-item label="出入口数量" prop="entry_exit_number">
              <el-input
                v-model="ruleForm.entry_exit_number"
                placeholder="请输入出入口数量"
                style="width:217px"
              >
                <i slot="suffix" style="font-style:normal">个</i>
              </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="市场经营类型" prop="market_operation_type">
              <el-select
                v-model="ruleForm.market_operation_type"
                placeholder="请选择市场经营类型"
              >
                <el-option
                  v-for="item in marketAddpage_info.market_operation_data"
                  :key="item.id"
                  :label="item.operation_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市场经营特色" prop="feature_type">
              <el-select
                v-model="ruleForm.feature_type"
                placeholder="请选择市场经营特色"
              >
                <el-option
                  v-for="item in marketAddpage_info.market_feature_data"
                  :key="item.id"
                  :label="item.features_title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市场经度" prop="longitude">
              <el-input
                v-model="ruleForm.longitude"
                placeholder="请输入市场经度"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="市场纬度" prop="latitude">
              <el-input
                v-model="ruleForm.latitude"
                placeholder="请输入市场纬度"
                style="width:217px"
              ></el-input>
            </el-form-item>
            <el-form-item label="市场信用等级" prop="market_credit_level">
              <el-rate v-model="ruleForm.market_credit_level"></el-rate>
            </el-form-item>
            <el-form-item label="检测室等级" prop="test_room_level">
              <el-rate v-model="ruleForm.test_room_level"></el-rate>
            </el-form-item>
            <el-form-item label="防疫防控等级" prop="epidemic_prevention_level">
              <el-rate v-model="ruleForm.epidemic_prevention_level"></el-rate>
            </el-form-item>
            <el-form-item label="公厕等级" prop="public_toilets_level">
              <el-rate v-model="ruleForm.public_toilets_level"></el-rate>
            </el-form-item>
          </div>
        </div>
        <h1 style="margin:0 0 20px;color:#409eff;font-size:20px">市场照片</h1>
        <div class="beteen margin">
          <el-form-item prop="market_business_licenses">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile1"
              >
                <img
                  v-if="ruleForm.market_business_licenses"
                  :src="ruleForm.market_business_licenses"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>市场证照</div>
            </div>
          </el-form-item>
          <el-form-item prop="market_principal">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile2"
              >
                <img
                  v-if="ruleForm.market_principal"
                  :src="ruleForm.market_principal"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>市场负责人免冠照</div>
            </div>
          </el-form-item>
          <el-form-item prop="market_plan">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile3"
              >
                <img
                  v-if="ruleForm.market_plan"
                  :src="ruleForm.market_plan"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>市场平面图</div>
            </div>
          </el-form-item>
          <el-form-item prop="fire_escape_plan">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile4"
              >
                <img
                  v-if="ruleForm.fire_escape_plan"
                  :src="ruleForm.fire_escape_plan"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>消防逃生图</div>
            </div>
          </el-form-item>
          <el-form-item prop="market_logo">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile5"
              >
                <img
                  v-if="ruleForm.market_logo"
                  :src="ruleForm.market_logo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>市场Logo</div>
            </div>
          </el-form-item>
          <el-form-item prop="market_picture">
            <div class="center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :before-upload="beforeAvatarUploads"
                :http-request="uploadSectionFile6"
              >
                <img
                  v-if="ruleForm.market_picture"
                  :src="ruleForm.market_picture"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>市场照片</div>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="市场简介" prop="intro">
          <el-input
            v-model="ruleForm.intro"
            placeholder="请输入市场简介"
            type="textarea"
            resize="none"
            maxlength="300"
            show-word-limit
          ></el-input>
        </el-form-item>

        <h1 style="margin:0 0 20px;color:#409eff;font-size:20px">消防要素</h1>
        <el-tabs type="border-card" @tab-click="handler" v-model="activeName1">
          <el-tab-pane label="消防水池" name="1">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop1"
            />
          </el-tab-pane>
          <el-tab-pane label="消防栓 " name="2">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop2"
            />
          </el-tab-pane>
          <el-tab-pane label="灭火器 " name="3">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop3"
            />
          </el-tab-pane>
          <el-tab-pane label="烟感" name="4">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop4"
            />
          </el-tab-pane>
          <el-tab-pane label="消防出入口" name="5">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop5"
            />
          </el-tab-pane>
          <el-tab-pane label="消防点 " name="6">
            <layoutPop1
              @Push="fireElementDataPush"
              :activeName1="activeName1"
              ref="layoutPop6"
            />
          </el-tab-pane>
          <el-tab-pane label="市场消防队人员" name="7">
            <layoutPop1
              :activeName1="activeName1"
              @Push="fireElementDataPush"
              ref="layoutPop7"
          /></el-tab-pane>
        </el-tabs>

        <el-form-item></el-form-item>
        <h1 style="margin:0 0 20px;color:#409eff;font-size:20px">服务设施</h1>
        <el-tabs type="border-card" @tab-click="handlers" v-model="activeName2">
          <el-tab-pane label="母婴室" name="2">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName22"
            />
          </el-tab-pane>
          <el-tab-pane label="残障人士通道" name="3">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName23"
            />
          </el-tab-pane>
          <el-tab-pane label="无害化处理点" name="4">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName24"
            />
          </el-tab-pane>
          <el-tab-pane label="志愿者服务站" name="6">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName26"
            />
          </el-tab-pane>
          <el-tab-pane label="停车位数量" name="5">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName25"
            />
          </el-tab-pane>
          <el-tab-pane label="公厕" name="1">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName21"
            />
          </el-tab-pane>
          <el-tab-pane label="智慧农贸工作室" name="7">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName27"
            />
          </el-tab-pane>
          <el-tab-pane label="消费者维权投诉站" name="8">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName28"
            />
          </el-tab-pane>
          <el-tab-pane label="公平秤" name="9">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName29"
            />
          </el-tab-pane>
          <el-tab-pane label="广播设备" name="10">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName210"
            />
          </el-tab-pane>
          <el-tab-pane label="垃圾收集分类" name="11">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName211"
            />
          </el-tab-pane>
          <el-tab-pane label="食品安全管理员" name="12">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName212"
            />
          </el-tab-pane>
          <el-tab-pane label="监控设备" name="13">
            <layoutPop2
              @Push="serviceFacilityPush"
              :activeName2="activeName2"
              ref="activeName213"
            />
          </el-tab-pane>
        </el-tabs>

        <el-form-item></el-form-item>
        <div style="text-align:right">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import layoutPop1 from './layoutPop1.vue'
import layoutPop2 from './layoutPop2.vue'
import { upload } from '../../utile/upload'
import { provinceCityArea, getcity, marketInfoadd } from '../../api/layout'
export default {
  name: 'layout_pops',
  components: {
    layoutPop1,
    layoutPop2
  },
  props: ['isShow', 'marketAddpage_info', 'title', 'ID'],
  data () {
    return {
      activeName1: '1', // 消防要素
      activeName2: '2', // 服务设施
      timePicker: '', // 营业时间数组
      // 请求的数据源
      infomation: {
        CityArea: '', // 省份
        City: '', // 城市
        Area: '', // 区县
        Street: '' // 街道
      },
      ruleForm: {
        comp_name: '', // 市场名称
        comp_id: '', // 市场编码
        reg_id: '', // 统一社会信用代码
        legal_represent: '', // 法人代表
        tel: '', // 联系电话
        fax: '', // 传真
        record_date: '', // 备案日期
        record_expiration_date: '', // 备案过期时间
        business_start: '', // 营业开始日期
        business_end: '', // 营业开始结束
        province_id: '', // 省
        city_id: '', // 城市
        area_id: '', // 隶属行政区划及代码
        street_id: '', // 街道
        addr: '', // 地址
        longitude: '', // 经度
        latitude: '', // 纬度
        market_footprint: '', // 市场面积
        entry_exit_number: '', // 出入口数量
        covered_population: '', // 覆盖人群约
        market_operation_type: '', // 市场经营类型
        feature_type: '', // 市场经营特色
        market_credit_level: 0, // 市场信用等级
        test_room_level: 0, // 检测室等级
        epidemic_prevention_level: 0, // 防疫防控等级
        public_toilets_level: 0, // 公厕等级
        market_business_licenses: '', // 市场证照
        market_principal: '', // 市场负责人免冠照
        market_plan: '', // 市场平面图
        fire_escape_plan: '', // 消防逃生图
        market_logo: '', // 市场Logo
        market_picture: '', // 市场照片
        intro: '', // 市场简介
        fire_element_data: [], // 消防要素json数据
        service_facility: [], // 服务设施json数据
        motorized_number: 0, // 机动车位数量 否
        motorized_addr: [], // 机动车位位置 否
        non_motorized_number: 0, // 非机动车位数量 否
        non_motorized_addr: [], // 非机动车位位置 否
        non_charging_number: 0, // 非充电桩车 位 否
        non_charging_addr: [] // 非充电桩车位位置 否
      },
      rules: {
        comp_name: [
          { required: true, message: '请输入市场名称', trigger: 'blur' }
        ],
        comp_id: [
          { required: true, message: '请输入市场编码', trigger: 'blur' }
        ],
        reg_id: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        record_date: [
          { required: true, message: '请选择备案日期', trigger: 'blur' }
        ],
        business_start: [
          { required: true, message: '请选择营业时间', trigger: 'blur' }
        ],
        province_id: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city_id: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        area_id: [{ required: true, message: '请选择区县', trigger: 'blur' }],
        street_id: [
          { required: true, message: '请选择乡镇街道', trigger: 'blur' }
        ],
        addr: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        longitude: [
          { required: true, message: '请输入地理经度', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '请输入地理维度', trigger: 'blur' }
        ],
        market_footprint: [
          { required: true, message: '请输入市场面积', trigger: 'blur' }
        ],
        entry_exit_number: [
          { required: true, message: '请输入市场出入口数量', trigger: 'blur' }
        ],
        covered_population: [
          { required: true, message: '请输入大约覆盖人口数量', trigger: 'blur' }
        ],
        market_operation_type: [
          { required: true, message: '请选择选择经营类型', trigger: 'blur' }
        ],
        feature_type: [
          { required: true, message: '请选择市场经营特色', trigger: 'blur' }
        ],
        market_credit_level: [
          { required: true, message: '请选择市场信用等级' }
        ],
        test_room_level: [{ required: true, message: '请选择检测室等级' }],
        epidemic_prevention_level: [
          { required: true, message: '请选择防疫防控等级' }
        ],
        public_toilets_level: [{ required: true, message: '请选择公厕等级' }],
        market_business_licenses: [
          { required: true, message: '请上传市场证照', trigger: 'blur' }
        ],
        market_principal: [
          { required: true, message: '请上传市场负责人免冠', trigger: 'blur' }
        ],
        market_plan: [
          { required: true, message: '请上传市场平面图', trigger: 'blur' }
        ],
        fire_escape_plan: [
          { required: true, message: '请上传消防逃生图', trigger: 'blur' }
        ],
        market_logo: [
          { required: true, message: '请上传市场logo', trigger: 'blur' }
        ],
        market_picture: [
          { required: true, message: '请上传市场照片', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    timePicker (val) {
      this.ruleForm.business_start = val[0]
      this.ruleForm.business_end = val[1]
    }
  },
  methods: {
    // 提示框显示隐藏
    handleClose () {
      this.$emit('closePop', false)
      this.$refs.layoutPop1.clear()
      this.$refs.layoutPop2.clear()
      this.$refs.layoutPop3.clear()
      this.$refs.layoutPop4.clear()
      this.$refs.layoutPop5.clear()
      this.$refs.layoutPop6.clear()
      this.$refs.layoutPop7.clear()
      this.clear()
      this.activeName1 = '1'
      this.activeName2 = '2'
      for (var i = 1; i <= 13; i++) {
        this.$refs.['activeName2' + i].clear()
      }
    },
    // 上传图片的校验
    beforeAvatarUploads (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径 市场证照
    uploadSectionFile1 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.market_business_licenses = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径 市场负责人免冠照
    uploadSectionFile2 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.market_principal = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径 市场平面图
    uploadSectionFile3 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.market_plan = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径 消防逃生图上传
    uploadSectionFile4 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.fire_escape_plan = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径 市场Logo
    uploadSectionFile5 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.market_logo = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径 市场照片1
    uploadSectionFile6 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.market_picture = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 省份
    province_CityArea () {
      provinceCityArea().then(res => {
        this.infomation.CityArea = res.data
      })
    },
    // 城市
    CityID () {
      this.ruleForm.city_id = ''
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.province_id).then(res => {
        this.infomation.City = res.data.children
      })
    },
    // 区县
    areaID () {
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.city_id).then(res => {
        this.infomation.Area = res.data.children
      })
    },
    // 街道
    streetID () {
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.area_id).then(res => {
        this.infomation.Street = res.data.children
      })
    },
    // 动态添加数组 消防要素
    fireElementDataPush (array, num, id) {
      this.ruleForm.fire_element_data.forEach((item, index) => {
        if (num * 1 === 0 && item.id * 1 === id * 1) {
          this.ruleForm.fire_element_data.splice(index, 1)
          return
        }
        if (item.id * 1 === id * 1) {
          item.intro = array
          item.number = num
        }
      })
      var status = this.ruleForm.fire_element_data.some(item => {
        return item.id * 1 === id * 1
      })
      if (!status && array.length !== 0) {
        this.ruleForm.fire_element_data.push({
          id: id,
          intro: array,
          number: num
        })
      }
    },
    // 动态添加数组 消防要素
    serviceFacilityPush (obj, num, id, r) {
      if (r && r === 1 && id * 1 === 5) {
        this.ruleForm.motorized_addr = obj
        this.ruleForm.motorized_number = num
        return
      }
      if (r && r === 1 && id * 1 === 5 && num * 1 === 0) {
        this.ruleForm.motorized_addr = []
        this.ruleForm.motorized_number = num
        return
      }
      if (r && r === 2 && id * 1 === 5) {
        this.ruleForm.non_motorized_addr = obj
        this.ruleForm.non_motorized_number = num
        return
      }
      if (r && r === 2 && id * 1 === 5 && num * 1 === 0) {
        this.ruleForm.non_motorized_addr = []
        this.ruleForm.non_motorized_number = num
        return
      }
      if (r && r === 3 && id * 1 === 5) {
        this.ruleForm.non_charging_number = num
        this.ruleForm.non_charging_addr = obj
        return
      }
      if (r && r === 3 && id * 1 === 5 && num * 1 === 0) {
        this.ruleForm.non_charging_number = num
        this.ruleForm.non_charging_addr = []
        return
      }
      this.ruleForm.service_facility.forEach((item, index) => {
        if (item.id === id && id !== 1 && id !== 5 && num * 1 === 0) {
          this.ruleForm.service_facility.splice(index, 1)
          return
        }
        if (item.id * 1 === id * 1 && id * 1 === 1) {
          item.info = obj
        }
        if (item.id * 1 === id * 1 && id * 1 !== 1 && id * 1 !== 5) {
          item.info = obj
          item.number = num
        }
      })
      var status = this.ruleForm.service_facility.some(item => {
        return item.id * 1 === id * 1
      })
      if (!status && id === 1) {
        this.ruleForm.service_facility.push({
          id: id,
          title: '公厕',
          info: obj
        })
      }
      if (!status && id * 1 !== 1 && id * 1 !== 5) {
        this.ruleForm.service_facility.push({
          id: id,
          number: num,
          info: obj
        })
      }
    },
    clear () {
      this.timePicker = ''
      this.ruleForm = {
        comp_name: '', // 市场名称
        comp_id: '', // 市场编码
        reg_id: '', // 统一社会信用代码
        legal_represent: '', // 法人代表
        tel: '', // 联系电话
        fax: '', // 传真
        record_date: '', // 备案日期
        business_start: '', // 营业开始日期
        record_expiration_date: '', // 备案过期时间
        business_end: '', // 营业开始结束
        province_id: '', // 省
        city_id: '', // 城市
        area_id: '', // 隶属行政区划及代码
        street_id: '', // 街道
        addr: '', // 地址
        longitude: '', // 经度
        latitude: '', // 纬度
        market_footprint: '', // 市场面积
        entry_exit_number: '', // 出入口数量
        covered_population: '', // 覆盖人群约
        market_operation_type: '', // 市场经营类型
        feature_type: '', // 市场经营特色
        market_credit_level: 0, // 市场信用等级
        test_room_level: 0, // 检测室等级
        epidemic_prevention_level: 0, // 防疫防控等级
        public_toilets_level: 0, // 公厕等级
        market_business_licenses: '', // 市场证照
        market_principal: '', // 市场负责人免冠照
        market_plan: '', // 市场平面图
        fire_escape_plan: '', // 消防逃生图
        market_logo: '', // 市场Logo
        market_picture: '', // 市场照片
        intro: '', // 市场简介
        fire_element_data: [], // 消防要素json数据
        service_facility: [], // 服务设施json数据
        motorized_number: 0, // 机动车位数量 否
        motorized_addr: [], // 机动车位位置 否
        non_motorized_number: 0, // 非机动车位数量 否
        non_motorized_addr: [], // 非机动车位位置 否
        non_charging_number: 0, // 非充电桩车位 否
        non_charging_addr: [] // 非充电桩车位位置 否
      }
    },
    // 提交按钮
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm,
            fire_element_data: JSON.stringify(this.ruleForm.fire_element_data),
            service_facility: JSON.stringify(this.ruleForm.service_facility)
          }
          if (this.title === '编辑市场' && this.ID) {
            data.id = this.ID
          } else {
            delete data.id
          }
          marketInfoadd(data).then(res => {
            if (res.code === 200) {
              this.clear()
              this.handleClose()
              this.$message.success(`${res.data.msg}`)
              this.$emit('marketList')
            } else {
              this.$message.error(`${res.msg}`)
            }
          })
        } else {
          return false
        }
      })
    },
    valueAtion () {
      this.$nextTick(async () => {
        this.activeName1 = this.marketAddpage_info.market_data.fire_element[0]
          ? `${this.marketAddpage_info.market_data.fire_element[0].element_id}`
          : '1'
        this.ruleForm.comp_name = this.marketAddpage_info.market_data.comp_name
        this.ruleForm.record_expiration_date = this.marketAddpage_info
          .market_data.record_expiration_date
          ? this.marketAddpage_info.market_data.record_expiration_date
          : ''
        this.ruleForm.comp_id = this.marketAddpage_info.market_data.comp_id
        this.ruleForm.reg_id = this.marketAddpage_info.market_data.reg_id
        this.ruleForm.reg_id = this.marketAddpage_info.market_data.reg_id
        this.ruleForm.tel = this.marketAddpage_info.market_data.tel
        this.ruleForm.fax = this.marketAddpage_info.market_data.fax
        this.ruleForm.record_date = this.marketAddpage_info.market_data.record_date
        this.timePicker = [
          this.marketAddpage_info.market_data.business_start,
          this.marketAddpage_info.market_data.business_end
        ]
        this.ruleForm.addr = this.marketAddpage_info.market_data.addr
        this.ruleForm.longitude = this.marketAddpage_info.market_data.other.longitude
        this.ruleForm.latitude = this.marketAddpage_info.market_data.other.latitude
        this.ruleForm.market_footprint = this.marketAddpage_info.market_data.other.market_footprint
        this.ruleForm.entry_exit_number = this.marketAddpage_info.market_data.other.entry_exit_number
        this.ruleForm.intro = this.marketAddpage_info.market_data.intro
        this.ruleForm.covered_population = this.marketAddpage_info.market_data.other.covered_population
        this.ruleForm.market_operation_type = this.marketAddpage_info.market_data.other.market_operation_type
        this.ruleForm.feature_type = this.marketAddpage_info.market_data.other.feature_type
        this.ruleForm.market_credit_level = this.marketAddpage_info.market_data.other.market_credit_level
        this.ruleForm.test_room_level = this.marketAddpage_info.market_data.other.test_room_level
        this.ruleForm.epidemic_prevention_level = this.marketAddpage_info.market_data.other.epidemic_prevention_level
        this.ruleForm.public_toilets_level = this.marketAddpage_info.market_data.other.public_toilets_level
        this.ruleForm.market_business_licenses = this.marketAddpage_info.market_data.imgs.market_business_licenses
        this.ruleForm.market_principal = this.marketAddpage_info.market_data.imgs.market_principal
        this.ruleForm.market_plan = this.marketAddpage_info.market_data.imgs.market_plan
        this.ruleForm.fire_escape_plan = this.marketAddpage_info.market_data.imgs.fire_escape_plan
        this.ruleForm.market_logo = this.marketAddpage_info.market_data.imgs.market_logo
        this.ruleForm.market_picture = this.marketAddpage_info.market_data.imgs.market_picture
        this.ruleForm.province_id = this.marketAddpage_info.market_data.province_id
        await this.CityID()
        this.ruleForm.city_id = this.marketAddpage_info.market_data.city_id
        await this.areaID()
        this.ruleForm.area_id = this.marketAddpage_info.market_data.area_id
        await this.streetID()
        this.ruleForm.street_id = this.marketAddpage_info.market_data.street_id
        this.item()
      })
    },
    // 编辑给子组件传值
    item () {
      this.marketAddpage_info.market_data.fire_element.forEach(item => {
        if (item.info.length === 0) {
          for (var i = 0; i <= item.number * 1 - 1; i++) {
            item.info.push({
              name: '', // 负责人姓名
              tel: '', // 负责人电话
              img: '', // 图片
              longitude: '', // 经度
              latitude: '', // 维度
              location_describe: '' // 位置描述
            })
          }
        }
        this.$refs.['layoutPop' + item.element_id].intros(item, this.$refs.['layoutPop' + item.element_id])
      })

      const publicToilet = this.marketAddpage_info.market_data.public_toilet // 公厕
      this.$refs.activeName21.Changehandle1(
        publicToilet,
        1,
        this.$refs.activeName21
      )

      const parkingSpaces = this.marketAddpage_info.market_data.parking_spaces // 机动车
      this.$refs.activeName25.Changehandle1(
        parkingSpaces,
        5,
        this.$refs.activeName25
      )

      const array = this.marketAddpage_info.market_data.service_facility // 其他数组
      array.forEach(item => {
        if (item.info.length === 0) {
          for (var i = 0; i <= item.number * 1 - 1; i++) {
            item.info.push({
              longitude: '', // 经度
              latitude: '', // 纬度
              location: '', // 位置
              img: ''
            })
          }
        }
        this.$refs.['activeName2' + item.sf_id].Changehandle1(item, item.sf_id, this.$refs.['activeName2' + item.sf_id])
      })
    },
    handler (val) {
      this.$refs.['layoutPop' + val.name].number(val.name)
    },
    handlers (val) {
      this.$refs.['activeName2' + val.name].number(val.name)
    }
  },
  mounted () {
    this.province_CityArea()
  }
}
</script>

<style lang="less" scoped>
.picker {
  /deep/ .el-input__icon {
    display: none;
  }
}
/deep/ .el-date-editor--timerange.el-input__inner {
  width: 220px;
}
/deep/ .el-rate__icon,
.el-rate__item {
  font-size: 30px;
}
.beteen {
  display: flex;
  justify-content: space-between;
}
.margin /deep/ .el-form-item__content {
  margin: 0 !important;
}
.center {
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
/deep/.center .avatar-uploader {
  line-height: 0;
}
</style>
