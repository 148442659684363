<template>
  <div>
    <div v-if="activeName * 1 === 1">
      <!-- 坑 位 -->
      <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">坑 位</h1>
      <div class="bent">
        <div>
          <h3>
            <b
              >男厕坑位数量
              <span style="margin-left:5px"
                >{{
                  fromData.man_choice_pee_squat +
                    fromData.man_choice_pee_sit +
                    fromData.man_choice_pee_squat_urinal +
                    fromData.man_choice_pee_sit_urinal
                }}个</span
              >
            </b>
          </h3>
          <div class="dispaly">
            <div>
              <b class="margins">大便器蹲式数量</b>
              <el-input-number
                v-model="fromData.man_choice_pee_squat"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
            <div>
              <b class="margin">大便器坐式数量</b>
              <el-input-number
                v-model="fromData.man_choice_pee_sit"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="dispaly">
            <div>
              <b class="margins">小便器挂试数量</b>
              <el-input-number
                v-model="fromData.man_choice_pee_squat_urinal"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
            <div>
              <b class="margin" style="margin-right:34px">小便池的数量</b>
              <el-input-number
                v-model="fromData.man_choice_pee_sit_urinal"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <h3 style="margin:15px 0px">
            <b
              >女厕坑位数量
              <span style="margin-left:5px"
                >{{
                  fromData.woman_choice_pee_squat +
                    fromData.woman_choice_pee_sit
                }}个</span
              ></b
            >
          </h3>
          <div class="dispaly">
            <div>
              <b class="margins">女蹲式坑位数量</b>
              <el-input-number
                v-model="fromData.woman_choice_pee_squat"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
            <div>
              <b class="margin">女坐式坑位数量</b>
              <el-input-number
                v-model="fromData.woman_choice_pee_sit"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div class="bent">
          <div style="margin:0 60px;text-align:center">
            <h4 style="margin-bottom:20px"><b>男厕照片</b></h4>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/"
              :before-upload="beforeAvatarUploads"
              :http-request="uploadSectionFile1"
            >
              <img
                v-if="fromData.man_imgs"
                :src="fromData.man_imgs"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div style="text-align:center;">
            <h4 style="margin-bottom:20px"><b>女厕照片</b></h4>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/"
              :before-upload="beforeAvatarUploads"
              :http-request="uploadSectionFile2"
            >
              <img
                v-if="fromData.woman_imgs"
                :src="fromData.woman_imgs"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <h3 style="margin:15px 0px">
        <b
          >残障人士(老人)专间
          <span style="margin-left:5px"
            >{{
              fromData.dp_sit +
                fromData.dp_wipe_handheld +
                fromData.dp_handheld
            }}个</span
          >
        </b>
      </h3>
      <div class="dispaly">
        <div>
          <b style="margin-right:31px">专间坐式数量</b>
          <el-input-number
            v-model="fromData.dp_sit"
            :min="0"
            :max="100"
          ></el-input-number>
        </div>
        <div>
          <b style="margin:0 28px">擦手设备数量</b>
          <el-input-number
            v-model="fromData.dp_wipe_handheld"
            :min="0"
            :max="100"
          ></el-input-number>
        </div>
        <div>
          <b style="margin:0 31px">扶手设备数量</b>
          <el-input-number
            v-model="fromData.dp_handheld"
            :min="0"
            :max="100"
          ></el-input-number>
        </div>
      </div>
      <!-- 洗手台  擦手纸设备 烘干机-->
      <div class="but">
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            洗手台
            <span style="margin-left:5px;color:#606266"
              >{{
                fromData.adult_wash_basin + fromData.child_wash_basin
              }}个</span
            >
          </h1>
          <div style="margin-left:30px">
            <div>
              <b style="margin-right:20px">成年洗手盆数量</b>
              <el-input-number
                v-model="fromData.adult_wash_basin"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
            <div style="margin-top:15px">
              <b style="margin-right:20px">儿童洗手盆数量</b>
              <el-input-number
                v-model="fromData.child_wash_basin"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            擦手纸设备
          </h1>
          <div style="margin-top:15px">
            <b style="margin-right:20px">擦手纸设备数量</b>
            <el-input-number
              v-model="fromData.paper_cleaning_equipment_num"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">烘干机</h1>
          <div style="margin-top:15px">
            <b style="margin-right:20px">烘干机数量</b>
            <el-input-number
              v-model="fromData.dryer_num"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
      </div>
      <!-- 除臭设备数量 防蚊灭蝇设备 空调 -->
      <div class="but">
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            除臭设备
          </h1>
          <div style="margin-left:30px">
            <b style="margin-right:34px">除臭设备数量</b>
            <el-input-number
              v-model="fromData.deodorize_num"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            防蚊灭蝇设备
          </h1>
          <div style="margin-left:30px">
            <b style="margin-right:20px">防蚊灭蝇设备数</b>
            <el-input-number
              v-model="fromData.mosquito_fly_num"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
        <div>
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            空调
          </h1>
          <div style="margin-left:30px">
            <b style="margin-right:20px">空调设备数量</b>
            <el-input-number
              v-model="fromData.air_num"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
      </div>
      <!-- 信息发布点 卫生负责人 卫生负责人免冠照 -->
      <div class="but">
        <div style="flex:1">
          <h1 style="margin:0 0 10px;color:#409eff;font-size:18px">
            信息发布点
            <span style="margin-left:5px;color:#606266"
              >{{ fromData.electronic + fromData.non_electronic }}个</span
            >
          </h1>
          <div style="margin-left:30px">
            <b style="margin-right:20px">电子数字式数量</b>
            <el-input-number
              v-model="fromData.electronic"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
          <div style="margin:15px 0 0 30px">
            <b style="margin-right:20px">电子数字式数量</b>
            <el-input-number
              v-model="fromData.non_electronic"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
        </div>
        <div style="flex:1;text-align:center">
          <h1
            style="margin:0 0 10px 20px;color:#409eff;font-size:18px;text-align:left"
          >
            卫生负责人
          </h1>
          <div style="margin:0 0  0 30px">
            <b style="margin-right:20px">卫生负责人</b>
            <el-input
              style="width:217px"
              placeholder="请输入卫生负责人姓名"
              v-model="fromData.health_name"
            ></el-input>
          </div>
          <div style="margin:15px 0 0 30px">
            <b style="margin-right:20px">负责人电话</b>
            <el-input
              style="width:217px"
              v-model="fromData.health_tel"
              placeholder="请输入卫生负责人电话"
            ></el-input>
          </div>
        </div>
        <div style="flex:1;text-align:center">
          <h1
            style="margin:0 0 10px 35px;color:#409eff;font-size:18px;text-align:left"
          >
            卫生负责人免冠照
          </h1>
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/"
            :before-upload="beforeAvatarUploads"
            :http-request="uploadSectionFile3"
          >
            <img
              v-if="fromData.health_img"
              :src="fromData.health_img"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div>
        <h1
          style="margin:0 0 10px;color:#409eff;font-size:18px;text-align:left"
        >
          公厕位置
        </h1>
        <div class="bent">
          <div class="bent" style="flex:1.1">
            <b>地理位置</b>
            <img
              src="https://www.wulihub.com.cn/gc/qVRawn/images/%E6%96%B0%E5%A2%9E%E5%B8%82%E5%9C%BA%E5%9F%BA%E6%9C%AC%E4%BF%A1%E6%81%AF/u151.png"
              style="width:30px;height: 30px;"
              alt=""
            />
            <el-input
              v-model="fromData.longitude"
              style="width:217px;margin:0 20px"
              placeholder="请输入地理经度"
            ></el-input>
            <el-input
              v-model="fromData.latitude"
              style="width:217px"
              placeholder="请输入地理维度"
            ></el-input>
          </div>
          <div class="bent" style="flex:0.9">
            <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
            <el-input
              v-model="fromData.location_describe"
              placeholder="请输入位置描述"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="activeName * 1 === 5">
      <!-- 机动车车位数量 -->
      <div>
        <h2 style="display: inline-block;margin-right:20px">机动车车位数量</h2>
        <el-input-number
          v-model="motorizedAddr"
          :min="0"
          :max="50"
        ></el-input-number>
        <b style="font-size:18px;margin-left:5px">个</b>
      </div>
      <!-- 位置描述 -->
      <div
        style=" display: flex; flex-wrap: wrap; justify-content: space-between;"
      >
        <div
          class="footers"
          v-for="(item, index) in motorizedAddr"
          :key="index"
        >
          <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
          <el-input
            v-model="motorized_addr[index]"
            type="textarea"
            clearable
            resize="none"
            placeholder="请输入位置描述"
          ></el-input>
        </div>
      </div>
      <!-- 非机动车车位数量 -->
      <div style="margin-top:15px">
        <h2 style="display: inline-block;margin-right:20px">非机动车位数量</h2>
        <el-input-number
          v-model="nonMotorizedAddr"
          :min="0"
          :max="50"
        ></el-input-number>
        <b style="font-size:18px;margin-left:5px">个</b>
      </div>

      <!-- 位置描述 -->
      <div
        style=" display: flex; flex-wrap: wrap; justify-content: space-between;"
      >
        <div
          class="footers"
          v-for="(item, index) in nonMotorizedAddr"
          :key="index"
        >
          <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
          <el-input
            v-model="non_motorized_addr[index]"
            type="textarea"
            clearable
            resize="none"
            placeholder="请输入位置描述"
          ></el-input>
        </div>
      </div>

      <!-- 非充电桩车位置 -->
      <div style="margin-top:15px">
        <h2 style="display: inline-block;margin-right:20px">非充电桩车位置</h2>
        <el-input-number
          v-model="nonCharginAddr"
          :min="0"
          :max="50"
        ></el-input-number>
        <b style="font-size:18px;margin-left:5px">个</b>
      </div>
      <!-- 位置描述 -->
      <div
        style=" display: flex; flex-wrap: wrap; justify-content: space-between;"
      >
        <div
          class="footers"
          v-for="(item, index) in nonCharginAddr"
          :key="index"
        >
          <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
          <el-input
            v-model="non_chargin_addr[index]"
            type="textarea"
            clearable
            resize="none"
            placeholder="请输入位置描述"
          ></el-input>
        </div>
      </div>
    </div>
    <div v-else-if="activeName * 1 === 6 || activeName * 1 === 8">
      <h2 style="display: inline-block;margin-right:20px">数量</h2>
      <el-input-number
        v-model="num"
        @change="handleChange"
        :min="0"
        :max="50"
      ></el-input-number>
      <b style="font-size:18px;margin-left:5px">处</b>
      <div
        class="footer"
        style="justify-content: space-between;"
        v-for="(item, index) in num"
        :key="index"
      >
        <div>
          <div style=" display: flex; align-items: center">
            <b>地理位置</b>
            <img
              src="https://www.wulihub.com.cn/gc/qVRawn/images/%E6%96%B0%E5%A2%9E%E5%B8%82%E5%9C%BA%E5%9F%BA%E6%9C%AC%E4%BF%A1%E6%81%AF/u151.png"
              style="width:30px;height: 30px;"
              alt=""
            />
            <el-input
              style="width:217px;margin:0 20px"
              placeholder="请输入地理经度"
              v-model="info[index].longitude"
            ></el-input>
            <el-input
              style="width:217px"
              v-model="info[index].latitude"
              placeholder="请输入地理维度"
            ></el-input>
          </div>
          <div
            style=" display: flex; align-items: center;margin-top:20px;width:100%"
          >
            <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
            <el-input
              v-model="info[index].location"
              placeholder="请输入位置描述"
            ></el-input>
          </div>
        </div>
        <div class="Index" @click="Index = index">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/"
            :before-upload="beforeAvatarUploads"
            :http-request="uploadSectionFile"
          >
            <img v-if="info[index].img" :src="info[index].img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 style="display: inline-block;margin-right:20px">数量</h2>
      <el-input-number
        v-model="num"
        @change="handleChange"
        :min="0"
        :max="50"
      ></el-input-number>
      <b v-if="activeName * 1 === 2" style="font-size:18px;margin-left:5px"
        >间</b
      >
      <b v-else-if="activeName * 1 === 9" style="font-size:18px;margin-left:5px"
        >台</b
      >
      <b v-else style="font-size:18px;margin-left:5px">处</b>
      <div class="footer" v-for="(item, index) in num" :key="index">
        <div class="footer" style="flex:1.1">
          <b>地理位置</b>
          <img
            src="https://www.wulihub.com.cn/gc/qVRawn/images/%E6%96%B0%E5%A2%9E%E5%B8%82%E5%9C%BA%E5%9F%BA%E6%9C%AC%E4%BF%A1%E6%81%AF/u151.png"
            style="width:30px;height: 30px;"
            alt=""
          />
          <el-input
            v-model="info[index].longitude"
            style="width:217px;margin:0 20px"
            placeholder="请输入地理经度"
          ></el-input>
          <el-input
            v-model="info[index].latitude"
            style="width:217px"
            placeholder="请输入地理维度"
          ></el-input>
        </div>
        <div class="footer" style="flex:0.9">
          <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
          <el-input
            v-model="info[index].location"
            placeholder="请输入位置描述"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from '../../utile/upload'
export default {
  name: 'layoutPop2',
  props: ['activeName2'],
  data () {
    return {
      num: 0, // 计数器数量
      baer: '',
      activeName: this.activeName2,
      info: [], // 公共数组
      Index: '',
      motorized_addr: [], // 机动车位位置
      motorizedAddr: 0, // 机动车数量
      non_motorized_addr: [], // 非机动车位位置
      nonMotorizedAddr: 0, // 非机动车位位置
      non_chargin_addr: [], // 非充电桩车位位置
      nonCharginAddr: 0,
      fromData: {
        man_choice_pee_squat: 0, // 大便器蹲式数量
        man_choice_pee_sit: 0, // 大便器坐式数量
        man_choice_pee_squat_urinal: 0, // 小便器挂试数量
        man_choice_pee_sit_urinal: 0, // 小便池数量
        woman_choice_pee_squat: 0, // 女蹲式坑位数量
        woman_choice_pee_sit: 0, // 女坐式坑位数量
        disabled_people: 0, // 残障人士专间: 1有，0没
        dp_sit: 0, // 专间坐式数量
        dp_wipe_handheld: 0, // 擦手设备数量
        dp_handheld: 0, // 扶手设备数量
        adult_wash_basin: 0, // 成年洗手盆数量
        child_wash_basin: 0, // 儿童洗手盆数量
        paper_cleaning_equipment: 0, // 擦手纸设备: 1有，0无
        paper_cleaning_equipment_num: 0, // 擦手纸设备数量
        dryer: 0, // 烘干机: 1有，0无
        dryer_num: 0, // 烘干机数量
        deodorize: 0, // 除臭设备: 1有，0无
        deodorize_num: 0, // 除臭设备数量
        mosquito_fly: 0, // 防蚊灭蝇设备: 1有，0无
        mosquito_fly_num: 0, // 防蚊灭蝇设备数量
        air: 0, // 空调: 1有，0无”
        air_num: 0, // 空调数量
        information_release_point: 0, // 信息发布点: 1有，0无
        electronic: 0, // 电子数字式数量
        non_electronic: 0, // 非电子数字式
        health_name: '', // 卫生负责人
        health_tel: '', // 卫生负责人电话
        health_img: '', // 卫生负责人免冠照
        woman_imgs: '', // 女厕所图片
        man_imgs: '', // 男厕所图片
        longitude: '', // 经度
        latitude: '', // 纬度
        location_describe: '' // 位置描述
      }
    }
  },
  watch: {
    'fromData.dp_wipe_handheld' () {
      this.fromData.disabled_people =
        this.fromData.dp_sit * 1 +
          this.fromData.dp_handheld * 1 +
          this.fromData.dp_wipe_handheld * 1 ===
        0
          ? 0
          : 1
    },
    'fromData.dp_handheld' () {
      this.fromData.disabled_people =
        this.fromData.dp_sit * 1 +
          this.fromData.dp_handheld * 1 +
          this.fromData.dp_wipe_handheld * 1 ===
        0
          ? 0
          : 1
    },
    'fromData.dp_sit' () {
      this.fromData.disabled_people =
        this.fromData.dp_sit * 1 +
          this.fromData.dp_handheld * 1 +
          this.fromData.dp_wipe_handheld * 1 ===
        0
          ? 0
          : 1
    },
    'fromData.paper_cleaning_equipment_num' (val) {
      if (val * 1 !== 0) {
        this.fromData.paper_cleaning_equipment = 1
      }
    },
    'fromData.dryer_num' (val) {
      this.fromData.dryer = val * 1 === 0 ? 0 : 1
    },
    'fromData.deodorize_num' (val) {
      this.fromData.deodorize = val * 1 === 0 ? 0 : 1
    },
    'fromData.mosquito_fly_num' (val) {
      this.fromData.mosquito_fly = val * 1 === 0 ? 0 : 1
    },
    'fromData.air_num' (val) {
      this.fromData.air = val * 1 === 0 ? 0 : 1
    },
    'fromData.electronic' () {
      this.fromData.information_release_point =
        this.fromData.electronic * 1 + this.fromData.non_electronic * 1 === 0
          ? 0
          : 1
    },
    'fromData.non_electronic' () {
      this.fromData.information_release_point =
        this.fromData.electronic * 1 + this.fromData.non_electronic * 1 === 0
          ? 0
          : 1
    },
    fromData: {
      handler (val) {
        this.$emit('Push', val, 1, 1)
      },
      deep: true
    },
    num (val) {
      this.baer = val
    },
    info: {
      handler (val) {
        this.$emit('Push', val, this.num, this.activeName)
      },
      deep: true
    },
    motorized_addr: {
      handler (val) {
        this.$emit('Push', val, this.motorizedAddr, this.activeName, 1)
      },
      deep: true
    },
    motorizedAddr: {
      handler () {
        this.$emit(
          'Push',
          this.motorized_addr,
          this.motorizedAddr,
          this.activeName,
          1
        )
      }
    },
    non_motorized_addr: {
      handler (val) {
        this.$emit('Push', val, this.nonMotorizedAddr, this.activeName, 2)
      },
      deep: true
    },
    nonMotorizedAddr: {
      handler () {
        this.$emit(
          'Push',
          this.non_motorized_addr,
          this.nonMotorizedAddr,
          this.activeName,
          2
        )
      }
    },
    nonCharginAddr: {
      handler () {
        this.$emit(
          'Push',
          this.non_chargin_addr,
          this.nonCharginAddr,
          this.activeName,
          3
        )
      }
    },
    non_chargin_addr: {
      handler (val) {
        this.$emit('Push', val, this.nonCharginAddr, this.activeName, 3)
      },
      deep: true
    }
  },
  methods: {
    // 上传图片的校验
    beforeAvatarUploads (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径  男厕所图片
    uploadSectionFile1 (param) {
      // this.fromData.man_imgs = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.fromData.man_imgs = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径  女厕所图片
    uploadSectionFile2 (param) {
      // this.fromData.woman_imgs = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.fromData.woman_imgs = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径  卫生负责人免冠照
    uploadSectionFile3 (param) {
      // this.fromData.health_img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.fromData.health_img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile (param) {
      // this.info[this.Index].img = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.info[this.Index].img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    handleChange (val) {
      if (this.baer > val) {
        this.info.pop()
        return
      }
      this.baer = val
      this.info.push({
        longitude: '', // 经度
        latitude: '', // 纬度
        location: '', // 位置
        img: ''
      })
    },
    number (val) {
      this.activeName = val
    },
    clear () {
      this.num = 0 // 计数器数量
      this.baer = ''
      this.activeName = this.activeName2
      this.info = [] // 公共数组
      this.Index = ''
      this.motorized_addr = [] // 机动车位位置
      this.motorizedAddr = 0 // 机动车数量
      this.non_motorized_addr = [] // 非机动车位位置
      this.nonMotorizedAddr = 0 // 非机动车位位置
      this.non_chargin_addr = [] // 非充电桩车位位置
      this.nonCharginAddr = 0
      this.fromData = {
        man_choice_pee_squat: 0, // 大便器蹲式数量
        man_choice_pee_sit: 0, // 大便器坐式数量
        man_choice_pee_squat_urinal: 0, // 小便器挂试数量
        man_choice_pee_sit_urinal: 0, // 小便池数量
        woman_choice_pee_squat: 0, // 女蹲式坑位数量
        woman_choice_pee_sit: 0, // 女坐式坑位数量
        disabled_people: 0, // 残障人士专间: 1有，0没
        dp_sit: 0, // 专间坐式数量
        dp_wipe_handheld: 0, // 擦手设备数量
        dp_handheld: 0, // 扶手设备数量
        adult_wash_basin: 0, // 成年洗手盆数量
        child_wash_basin: 0, // 儿童洗手盆数量
        paper_cleaning_equipment: 0, // 擦手纸设备: 1有，0无
        paper_cleaning_equipment_num: 0, // 擦手纸设备数量
        dryer: 0, // 烘干机: 1有，0无
        dryer_num: 0, // 烘干机数量
        deodorize: 0, // 除臭设备: 1有，0无
        deodorize_num: 0, // 除臭设备数量
        mosquito_fly: 0, // 防蚊灭蝇设备: 1有，0无
        mosquito_fly_num: 0, // 防蚊灭蝇设备数量
        air: 0, // 空调: 1有，0无”
        air_num: 0, // 空调数量
        information_release_point: 0, // 信息发布点: 1有，0无
        electronic: 0, // 电子数字式数量
        non_electronic: 0, // 非电子数字式
        health_name: '', // 卫生负责人
        health_tel: '', // 卫生负责人电话
        health_img: '', // 卫生负责人免冠照
        woman_imgs: '', // 女厕所图片
        man_imgs: '', // 男厕所图片
        longitude: '', // 经度
        latitude: '', // 纬度
        location_describe: '' // 位置描述
      }
    },
    Changehandle1 (val, id, news) {
      if (!val) return
      if (this === news && id * 1 === 1) {
        this.fromData = val
        this.activeName = 1
      } else if (this === news && id * 1 === 5) {
        this.activeName = 5
        this.motorizedAddr = val.motorized_number
        this.nonMotorizedAddr = val.non_motorized_number
        this.nonCharginAddr = val.non_charging_number
        this.non_motorized_addr = val.non_motorized_addr
          ? val.non_motorized_addr
          : []
        this.non_chargin_addr = val.non_charging_addr
          ? val.non_charging_addr
          : []
        this.motorized_addr = val.motorized_addr ? val.motorized_addr : []
      } else {
        if (this === news) {
          this.num = val.number
          this.activeName = id
          val.info.forEach(item => {
            if (item.sf_id) {
              this.info.push({
                id: val.sf_id,
                longitude: item.longitude, // 经度
                latitude: item.latitude, // 纬度
                location: item.location_describe, // 位置
                img: item.img
              })
            } else {
              this.info.push({
                id: '',
                longitude: item.longitude, // 经度
                latitude: item.latitude, // 纬度
                location: '', // 位置
                img: item.img
              })
            }
          })
        }
      }
    }
  }
  // mounted () {}
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.footers {
  display: flex;
  width: 49%;
  align-items: center;
  margin-top: 15px;
}
.Index {
  flex: 1;
  text-align: center;
}
.dispaly {
  display: flex;
  margin-top: 15px;
  margin-left: 30px;
}
.but {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.bent {
  display: flex;
  align-items: center;
}
.margin {
  margin: 0 20px;
}
.margins {
  margin-right: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
/deep/ .el-input__suffix {
  margin-top: 10px;
}
</style>
