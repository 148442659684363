<template>
  <div class="market_list">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="省份筛选">
        <el-select v-model="formInline.r1" placeholder="城市筛选">
          <el-option
            v-for="item in provinceR1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="provincesR1(item.id)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市筛选">
        <el-select v-model="formInline.r2" placeholder="城市筛选">
          <el-option
            v-for="item in cityR2.children"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="citychildrenR2(item.id)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市场经营类型">
        <el-select v-model="formInline.r3" placeholder="市场经营类型">
          <el-option
            v-for="item in marketTypeR3"
            :key="item.id"
            :label="item.operation_title"
            :value="item.id"
            @click.native="marketTypesR3(item.id)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市场筛选">
        <el-select v-model="formInline.r4" placeholder="市场筛选">
          <el-option
            v-for="item in marketR4"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="mkidR4(item.id)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
        <el-button @click="derive">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table
        :data="tableData.data"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
        max-height="calc(100vh - 160px-42px)"
      >
        <el-table-column prop="comp_id" label="市场编号"> </el-table-column>
        <el-table-column prop="comp_name" label="市场名称"> </el-table-column>
        <el-table-column prop="addr" label="地址"> </el-table-column>
        <el-table-column
          prop="manage_personnels"
          label="市场负责人"
          width="100"
        >
        </el-table-column>
        <el-table-column prop="merchant" label="商户数" width="100">
        </el-table-column>
        <el-table-column prop="booth" label="摊位" width="100">
        </el-table-column>
        <el-table-column prop="business_start" label="营业时间">
        </el-table-column>
        <el-table-column width="100" label="证照">
          <template>
            <el-link type="primary">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column label="消防逃生图/平面图">
          <template>
            <el-link type="primary">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="operation_title" label="经营类型" width="100">
        </el-table-column>
        <el-table-column
          prop="market_credit_level"
          width="100"
          label="市场等级"
        >
        </el-table-column>
        <el-table-column label="消防六要素" width="100">
          <template>
            <el-link type="primary">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column label="服务设施" width="100">
          <template>
            <el-link type="primary">查看</el-link>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <layoutPop
      :isShow="dialogVisible"
      :title="title"
      :marketAddpage_info="marketAddpage_info"
      @closePop="closePop"
      @marketList="market_list"
      :ID="ID"
      ref="layoutPop"
    />
  </div>
</template>

<script>
import layoutPop from '@/components/layout_pop/layout_pop'
import {
  provinceCityArea,
  getcity,
  operation,
  getmarket,
  marketlist,
  marketAddpage
} from '../../../api/layout'
export default {
  name: 'market_list',
  components: {
    layoutPop
  },
  data () {
    return {
      formInline: {
        r1: '',
        r2: '',
        r3: '',
        r4: ''
      },
      title: '',
      ID: '', // 修改时的ID
      ruleForm: {},
      rules: {},
      provinceR1: '', // 省份数据
      cityR2: '', // 城市数据
      marketTypeR3: '', // 市场经营类型数据
      marketR4: '', // 市场筛选
      tableData: '',
      dialogVisible: false, // 弹窗
      marketAddpage_info: '' // 经营特色和经营
    }
  },

  methods: {
    // 编辑
    handleEdit (e) {
      this.ID = e.id
      this.dialogVisible = true
      this.title = '编辑市场'
      this.getMarketAddpage(e.id)
    },
    // 新增
    add () {
      this.dialogVisible = true
      this.title = '新增市场'
      this.getMarketAddpage()
    },
    // 导出
    derive () {
      console.log(11)
    },
    // 提示框显示隐藏
    closePop (val) {
      this.dialogVisible = val
      this.market_list()
    },
    // 选择啦省份
    provincesR1 (id) {
      getcity(id).then(res => {
        if (res.code === 200) {
          this.cityR2 = res.data
        }
      })
      this.formInline.r2 = ''
    },
    // 选择啦城市
    citychildrenR2 (id) {
      this.market_list({ provinceid: id })
    },
    // 选择了市场经营类型
    marketTypesR3 (id) {
      this.market_list({ marketoperationtype: id })
      console.log(id)
    },
    // 选择了市场筛选
    mkidR4 (id) {
      this.market_list({ mkid: id })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.market_list({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.market_list({ page: val })
    },
    // 请求表格数据
    market_list ({
      page = '',
      limit = '',
      mkid = '',
      provinceid = '',
      marketoperationtype = ''
    } = {}) {
      const data = {
        page, // 页码
        limit, // 条数 默认10
        mkid, // 市场
        provinceid, // 城市id
        marketoperationtype // 市场经营类型
      }
      marketlist(data).then(res => {
        this.tableData = res.data
      })
    },
    async getMarketAddpage (e = '') {
      const res = await marketAddpage(e)
      this.marketAddpage_info = res.data
      if (this.title === '编辑市场' && this.marketAddpage_info) {
        this.$refs.layoutPop.valueAtion()
      }
    }
  },
  mounted () {
    provinceCityArea().then(res => {
      if (res.code === 200) {
        this.provinceR1 = res.data
        getcity(res.data[0].id).then(res => {
          if (res.code === 200) {
            this.cityR2 = res.data
          }
        })
      }
    })
    operation().then(res => {
      this.marketTypeR3 = res.data
    })
    getmarket().then(res => {
      this.marketR4 = res.data
    })
    this.market_list()
  }
}
</script>

<style lang="less" scoped>
.footer {
  text-align: right;
  margin-top: 10px;
}
</style>
