<template>
  <div>
    <div>
      <h2 style="display: inline-block;margin-right:20px">数量</h2>
      <el-input-number
        v-model="num1"
        @change="handleChange"
        :min="0"
        :max="50"
      ></el-input-number>
    </div>
    <div v-if="activeName * 1 === 7">
      <div class="header">
        <div
          v-for="(item, index) in num1"
          :key="index"
          class="dispaly width"
          style="margin-top:15px"
        >
          <div class="beten">
            <div>
              <b>人员姓名</b>
              <el-input
                v-model="intro[index].name"
                class="margin"
                placeholder="请输入人员姓名"
              ></el-input>
            </div>
            <div>
              <b>人员电话</b>
              <el-input
                v-model="intro[index].tel"
                class="margin"
                placeholder="请输入人员电话"
              ></el-input>
            </div>
          </div>
          <div @click="Index = index">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/"
              :before-upload="beforeAvatarUploads"
              :http-request="uploadSectionFile"
            >
              <img
                v-if="intro[index].img"
                :src="intro[index].img"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="dispaly"
        style="margin-top:15px"
        v-for="(item, index) in num1"
        :key="index"
      >
        <div class="dispaly" style="flex:1.1">
          <b>地理位置</b>
          <img
            src="https://www.wulihub.com.cn/gc/qVRawn/images/%E6%96%B0%E5%A2%9E%E5%B8%82%E5%9C%BA%E5%9F%BA%E6%9C%AC%E4%BF%A1%E6%81%AF/u151.png"
            style="width:30px;height: 30px;"
            alt=""
          />
          <el-input
            v-model="intro[index].longitude"
            style="width:217px;margin:0 20px"
            placeholder="请输入地理经度"
          ></el-input>
          <el-input
            v-model="intro[index].latitude"
            style="width:217px"
            placeholder="请输入地理维度"
          ></el-input>
        </div>
        <div class="dispaly" style="flex:0.9">
          <b style=" flex-shrink: 0;margin-right:20px">位置描述</b>
          <el-input
            v-model="intro[index].location_describe"
            placeholder="请输入位置描述"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from '../../utile/upload'
export default {
  name: 'layoutPop1',
  props: ['activeName1'],
  data () {
    return {
      num1: 0, // 数量
      baer: '',
      intro: [], // 动态添加数据
      Index: '',
      activeName: this.activeName1
    }
  },
  watch: {
    num1 (val) {
      this.baer = val
    },
    intro: {
      handler: function (val) {
        this.$emit('Push', val, this.num1, this.activeName)
      },
      deep: true
    }
  },
  methods: {
    // 计数变化
    handleChange (val) {
      if (this.baer > val) {
        this.intro.pop()
        return
      }
      this.baer = val
      this.intro.push({
        id: '',
        name: '', // 负责人姓名
        tel: '', // 负责人电话
        img: '', // 图片
        longitude: '', // 经度
        latitude: '', // 维度
        location_describe: '' // 位置描述
      })
    },
    // 上传图片的校验
    beforeAvatarUploads (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径 市场证照
    uploadSectionFile (param) {
      upload(param)
        .then(res => {
          this.intro[this.Index].img = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    number (val) {
      this.activeName = val
    },
    // 编辑赋值
    intros (val, news) {
      if (this === news) {
        this.num1 = val.number * 1
        this.activeName = val.element_id
        val.info.forEach(item => {
          if (item) {
            if (item.id) {
              this.intro.push({
                id: item.id,
                name: item.name, // 负责人姓名
                tel: item.tel, // 负责人电话
                img: item.img, // 图片
                longitude: item.longitude, // 经度
                latitude: item.latitude, // 维度
                location_describe: item.location_describe // 位置描述
              })
            } else {
              this.intro.push({
                id: '',
                name: item.name, // 负责人姓名
                tel: item.tel, // 负责人电话
                img: item.img, // 图片
                longitude: item.longitude, // 经度
                latitude: item.latitude, // 维度
                location_describe: item.location_describe // 位置描述
              })
            }
          }
        })
      }
    },
    clear () {
      this.num1 = 0
      this.intro = []
      this.baer = ''
      this.Index = ''
    }
  }
}
</script>

<style lang="less" scoped>
.dispaly {
  display: flex;
  align-items: center;
}

.margin {
  margin: 0 60px 0 20px;
  width: 217px;
}
.width {
  flex: 1;
  text-align: left;
}
.header {
  display: flex;
  flex-wrap: wrap;
}
.beten {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
